<template >
  <v-col :cols="12" :class="mainContainerClass">
    <div class="grey--text text-right" >
      {{ label }}
    </div>
    <v-autocomplete
      :background-color="$attrs.disabled ? 'grey' : 'grey lighten-2'"
      solo
      hide-details="auto"
      flat
      class="rounded-lg"
      no-data-text="هیچ نتیجه ای پیدا نشد."
      v-model="inputVal"
      v-bind="$attrs"
      v-if="inputType === 'autocomplete'"
    />
    <v-select
      :background-color="$attrs.disabled ? 'grey' : 'grey lighten-2'"
      solo
      hide-details="auto"
      flat
      class="rounded-lg"
      no-data-text="هیچ نتیجه ای پیدا نشد."
      v-model="inputVal"
      v-bind="$attrs"
      v-else-if="inputType === 'select'"
    />
    <v-textarea
      :background-color="$attrs.disabled ? 'grey' : 'grey lighten-2'"
      solo
      hide-details="auto"
      flat
      class="rounded-lg"
      v-model="inputVal"
      v-bind="$attrs"
      v-else-if="inputType === 'textarea'"
    />
    <v-color-picker
      class="mx-auto"
      mode="hexa"
      hide-mode-switch
      v-model="inputVal"
      v-bind="$attrs"
      v-else-if="inputType === 'colorpicker'"
    />
    <v-checkbox
      class="rounded-lg"
      v-model="inputVal"
      v-bind="$attrs"
      v-else-if="inputType === 'checkbox'"
    />
    <v-text-field
      :background-color="$attrs.disabled ? 'grey' : 'grey lighten-2'"
      solo
      hide-details="auto"
      flat
      class="rounded-lg"
      v-model="inputVal"
      v-bind="$attrs"
      label=""
      v-else
    />
  </v-col>
</template>

<script>
import { persianNumToEnglish } from '../constants/GlobalConstants';

export default {
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      default: 'input',
    },
  },
  computed: {
    mainContainerClass(){
      return{
        "pa-2": true,
        'checkbox-container': this.inputType === 'checkbox'
      }
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        if (typeof val == 'string')
          this.$emit('input', persianNumToEnglish(val?.trim()));
        else this.$emit('input', val);
      },
    },
  },
};
</script>

<style scoped>
.checkbox-container{
  display:flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  padding-top: 22px !important;
}
</style>
